
export function submitActiveCampaignForm(data, message = '') {
  // const form = document.getElementById('_form_7_');
  // const values = formSerialize(form);
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "23"); // Form SignUp Provisional Builder
  formData.append("f", "23"); // https://patent.activehosted.com/app/forms/23
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "f0cf315029345242e5c59262df7950e0");

  // Form field key/values.
  for (const [key, value] of Object.entries(data)) {
    let fieldName = key;
    switch(key) {
      case 'name':
        fieldName = 'fullname';
        break;
      case 'interest':
        fieldName = 'field[36]';
        break;
      case 'invention':
        fieldName = 'field[37]';
        break;

      case 'team':
        fieldName = 'field[38]';
        break;

      case 'meeting':
        fieldName = 'field[39]';
        break;
      default:
        fieldName = key;
    }
    formData.append(fieldName, value);
  }

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
    // headers: {
    //   'Api-Token': 'f7c9c6bc056b48c06bb225bd42fba918149e90463992958b6a50b8001ef9de9cd8809bc5',
    //   'Content-Type': 'application/json'
    // }
  }).then(res => {
    if (message) { alert(message) }
  })
    // .then(data => console.log(data))
    .catch(err => {
      console.log(err)
      alert('Something wrong. Please try again later!');
    });
}

export function submitInventivEwebinarForm(data, message = '') {
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "37");
  formData.append("f", "37");
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "ca47fa23e6cba446e39d12cbde9a9a3c");

  // Form field key/values.
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
    // headers: {
    //   'Api-Token': 'f7c9c6bc056b48c06bb225bd42fba918149e90463992958b6a50b8001ef9de9cd8809bc5',
    //   'Content-Type': 'application/json'
    // }
  }).then(res => {
    if (message) { alert(message) }
  })
    // .then(data => console.log(data))
    .catch(err => {
      console.log(err)
      alert('Something wrong. Please try again later!');
    });
}

export function submitInventivPatentStrategyForm(data, message = '') {
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "49");
  formData.append("f", "49");
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "276745333cf50d28b68e6d602a437964");

  // Form field key/values.
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
    // headers: {
    //   'Api-Token': 'f7c9c6bc056b48c06bb225bd42fba918149e90463992958b6a50b8001ef9de9cd8809bc5',
    //   'Content-Type': 'application/json'
    // }
  }).then(res => {
    if (message) { alert(message) }
  })
    // .then(data => console.log(data))
    .catch(err => {
      console.log(err)
      alert('Something wrong. Please try again later!');
    });
}

export function submitInventivContactForm(data, message = '') {
  const formData = new FormData();

  // Hidden field key/values.
  formData.append("u", "45"); // Form inventiv - Contact
  formData.append("f", "45"); // https://patent.activehosted.com/f/45
  formData.append("s", null);
  formData.append("c", "0");
  formData.append("m", "0");
  formData.append("act", "sub");
  formData.append("v", "2");
  formData.append("or", "a0f7865ec25f6dde4534f91f0c14a059");

  // Form field key/values.
  for (const [key, value] of Object.entries(data)) {
    let fieldName = key;
    switch(key) {
      case 'name':
        fieldName = 'fullname';
        break;
      case 'subject':
        fieldName = 'field[44]';
        break;
      case 'message':
        fieldName = 'field[45]';
        break;
      default:
        fieldName = key;
    }
    formData.append(fieldName, value);
  }

  fetch(`https://patent.activehosted.com/proc.php`, {
    mode: "no-cors",
    method: 'POST',
    body: formData,
  }).then(res => {
    if (message) { alert(message) }
  })
  .catch(err => {
    console.log(err)
    alert('Something wrong. Please try again later!');
  });
}
